import React from 'react';

import constants from 'client/config';
import { Order } from 'client/services/order/types/order';

import { InfoDivider } from '../../Invoice.styled';
import {
  InvoiceMainInfoContainer,
  IssuerInfoContainer,
  IssuerHeader,
  IssuerHeading,
  InvoiceIssuerInfo,
  InnerIssuerHeader,
  Info,
  InfoCustomerId,
  BilledInfoContainer,
} from './InvoiceMainInfo.styled';

type OrdersItemProps = {
  orderData: Order;
};

export const InvoiceMainInfo: React.FC<OrdersItemProps> = ({ orderData }) => {
  return (
    <InvoiceMainInfoContainer>
      <IssuerInfoContainer>
        <IssuerHeading>
          <IssuerHeader variant="h3">Issuer:</IssuerHeader>
        </IssuerHeading>
        <InfoDivider />
        <InvoiceIssuerInfo>
          <IssuerHeading>
            <InnerIssuerHeader variant="h4">
              {constants.company.name}
            </InnerIssuerHeader>
          </IssuerHeading>
          <InvoiceIssuerInfo>
            <Info variant="body2">Trading as Ultraviolet SIM</Info>
            <Info variant="body2">ABN: {constants.company.ABN}</Info>
            <Info variant="body2">{constants.company.address},</Info>
            <Info variant="body2">{constants.company.region}</Info>
          </InvoiceIssuerInfo>
        </InvoiceIssuerInfo>
      </IssuerInfoContainer>
      <BilledInfoContainer>
        <IssuerHeading>
          <IssuerHeader variant="h3">Billed to:</IssuerHeader>
          <InfoCustomerId>Customer ID: {orderData.clientId}</InfoCustomerId>
        </IssuerHeading>
        <InfoDivider />
        <InvoiceIssuerInfo>
          <IssuerHeading>
            <InnerIssuerHeader variant="h4">
              {orderData.client}
            </InnerIssuerHeader>
          </IssuerHeading>
          <InvoiceIssuerInfo>
            <Info variant="body2">{orderData.client}</Info>
            <Info variant="body2">
              {orderData.addressLine1},{orderData.zipcode},{orderData.city}
            </Info>
            <Info variant="body2">{orderData.country}</Info>
          </InvoiceIssuerInfo>
        </InvoiceIssuerInfo>
      </BilledInfoContainer>
    </InvoiceMainInfoContainer>
  );
};
