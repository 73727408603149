import React, { useContext, useEffect } from 'react';

import { useMediaQuery } from '@mui/material';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';

import { dark, light } from 'client/themes';

import useAccountSettings from '../hooks/useAccountSettings';
import useAuthContext from '../hooks/useAuthContext';

type ColorContext = {
  toggleColorMode: () => void;
};

export const ColorModeContext = React.createContext<ColorContext>({
  toggleColorMode: () => {},
});

export const ThemeProvider: React.FC = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const { user } = useAuthContext();
  const { setAccountSettings } = useAccountSettings();
  const [mode, setMode] = React.useState(dark);

  useEffect(() => {
    if (user.accountSettings && user.accountSettings.theme)
      setMode(user.accountSettings.theme === 'dark' ? dark : light);
    else setMode(prefersDarkMode ? dark : light);
  }, [prefersDarkMode]);
  const toggleColorMode = () => {
    setMode((prevMode) => {
      setAccountSettings({ theme: prevMode === light ? 'dark' : 'light' });
      return prevMode === light ? dark : light;
    });
  };

  return (
    <ColorModeContext.Provider
      value={{
        toggleColorMode,
      }}
    >
      <MaterialThemeProvider theme={mode}>{children}</MaterialThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default function useColorContext(): ColorContext {
  const colorContext = useContext(ColorModeContext);
  if (colorContext === undefined)
    throw new Error('toggleColorMode must be used within a useColorContext');

  return colorContext as ColorContext;
}
