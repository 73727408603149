import axios, {
  AxiosHeaders,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { getAccessToken } from "./local-storage";

export const apiClient = axios.create({
  baseURL:
    (typeof window !== "undefined" && window.env.API_BASE_URL) ||
    process.env.API_BASE_URL ||
    API_BASE_URL,
});

const instance = axios.create({
  baseURL:
    (typeof window !== "undefined" && window.env.API_BASE_URL) ||
    process.env.API_BASE_URL ||
    API_BASE_URL,
    withCredentials: true,
});

const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  try {
    const accessToken =
      getAccessToken() || localStorage.getItem("access_token");

    if (accessToken) {
      const mHeaders = AxiosHeaders.from({
        Authorization: `Bearer ${accessToken}`,
      }) as AxiosRequestHeaders;

      if (mHeaders) {
        config.headers = mHeaders;
      }
    }
  } catch (error) {
    console.error(error);
  }

  return config;
};

instance.interceptors.request.use(onRequest);

// Response interceptor to process the response
instance.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error("Unauthorized access");
    }
    return Promise.reject(error);
  }
);

export default instance;
