export default {
  company: {
    name: 'Ultraviolet SIM Pty Ltd',
    address: 'Suite 1.02 / 102 Bennelong Parkway',
    region: 'Sydney Olympic Park NSW 2127 Australia',
    ABN: '82 651 291 100',
    email: 'finance@ultravoilet.com',
  },
  imageExtensions: ['jpeg', 'png', 'jpg'],
  simsImportFileExtensions: ['csv', 'vnd.ms-excel'],
  payment: {
    currency: {
      default: 'usd',
    },
    key: {
      public:
        (typeof window !== 'undefined' && window.env.STRIPE_PUBLIC_KEY) ||
        process.env.STRIPE_PUBLIC_KEY ||
        STRIPE_PUBLIC_KEY,
    },
  },
  shipping: {
    default: {
      type: 'basic',
      service: 'ultraviolet',
    },
  },
  fee: {
    default: 0,
    percent: 2.9,
    cents: 30,
  },
};
