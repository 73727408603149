import { useCallback, useMemo } from 'react';

import useAuthContext from 'client/hooks/useAuthContext';
import { RolesEnum } from 'client/services/roles/enums/roles.enum';

import { RoleGroupsEnum } from '../enums/role-groups.enum';

const ROLE_GROUPS = {
  [RoleGroupsEnum.Admin]: [
    RolesEnum.AdminTeamSuperAdmin,
    RolesEnum.AdminTeamAdmin,
    RolesEnum.AdminTeamManager,
  ],
  [RoleGroupsEnum.Client]: [
    RolesEnum.ClientTeamAdmin,
    RolesEnum.ClientTeamUser,
  ],
};

type PermissionType = {
  hasRole: (role: RolesEnum | RoleGroupsEnum) => boolean;
};

const usePermission = (): PermissionType => {
  const { user } = useAuthContext();
  const hasRole = useCallback(
    (role: RolesEnum | RoleGroupsEnum) =>
      role === user?.role?.name ||
      (ROLE_GROUPS[role as RoleGroupsEnum] &&
        ROLE_GROUPS[role as RoleGroupsEnum].includes(user?.role?.name)),
    [user],
  );

  return useMemo(() => ({ hasRole }), [hasRole]);
};

export default usePermission;
