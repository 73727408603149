import React from 'react';

import { OutlinedInput } from '@mui/material';

import { ErrorText } from 'client/components';

import { InputCaption, InputControl } from './CreateFormInput.styled';

type InputProps = {
  label?: string;
  required?: boolean;
  errorText?: string;
  error?: boolean;
  validationRegister: unknown;
};

export const CreateFormInput: React.FC<InputProps> = ({
  label,
  required,
  errorText,
  validationRegister,
  error,
  ...rest
}) => {
  return (
    <InputControl variant="outlined" size="small" required={required}>
      {label && <InputCaption shrink={true}>{label}</InputCaption>}
      <OutlinedInput {...validationRegister} error={error} {...rest} />
      <ErrorText variant="inherit">{errorText}</ErrorText>
    </InputControl>
  );
};

export default CreateFormInput;
