import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import * as usersApi from "client/services/user";
import { User } from "client/services/user/types/user.type";

import useNotification from "./useNotification";
import { getAccessToken } from "client/utils/local-storage";
interface AuthContextType {
  loading: boolean;
  user: User;
  setUserContext: () => Promise<void>;
  setUser: (user: User) => void;
  setUserContextByToken: (token: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [user, setUser] = useState<User>({} as User);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const { setError } = useNotification();

  useEffect(() => {
    const currentToken = getAccessToken();
    if (currentToken) {
      usersApi
        .getCurrentUserWithToken(currentToken) 
        .then((user) => {
          setUser(user);
        })
        .finally(() => setLoadingInitial(false));
    } else {
      setLoadingInitial(false);
    }
  }, []);


  const setUserContext = useCallback(async () => {
    try {
      const currentToken = getAccessToken();
      if (currentToken) {
        setUser(await usersApi.getCurrentUserWithToken(currentToken));
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [setUser, setError, setLoading]);
  

  const setUserContextByToken = useCallback(async (token: string) => {
    try {
      setUser(await usersApi.getCurrentUserWithToken(token));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      loading,
      user,
      setUserContext,
      setUser,
      setUserContextByToken,
    }),
    [loading, user, setUserContext, setUser, setUserContextByToken]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

export const useAuthContext = function (): AuthContextType {
  const authContext = useContext(AuthContext);
  if (authContext === undefined)
    throw new Error("useAuth must be used within a AuthProvider");

  return authContext;
};

export default useAuthContext;
