import Cookies from "js-cookie";

const ACCESS_TOKEN_KEY = "access_token";

/**
 * Save the access token to local storage
 * @param token - The access token to be stored
 */
export const saveAccessToken = (token: string): void => {
console.log('tien - zzz');
  Cookies.set(ACCESS_TOKEN_KEY, token, {
    httpOnly: true,
    secure: process.env.NODE_ENV === "production",
    sameSite: process.env.NODE_ENV === "production" ? "none" : "lax",
    expires: 7,
  });
console.log('tien - zzz1',Cookies.get(ACCESS_TOKEN_KEY));
};

/**
 * Retrieve the access token from local storage
 * @returns The stored token or undefined if not found
 */
export const getAccessToken = (): string | undefined => {
  return Cookies.get(ACCESS_TOKEN_KEY);
};

/**
 * Remove the access token from local storage
 */
export const removeAccessToken = (): void => {
  Cookies.remove(ACCESS_TOKEN_KEY);
};
