import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RecordWrapper = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  marginTop: 20,
  '&:first-of-type': {
    marginTop: 0,
  },
}));

export const RecordLabel = styled(Typography)(() => ({
  color: '#ffffff',
  textTransform: 'capitalize',
}));

export const RecordValue = styled(Typography)(() => ({
  color: '#ffffff',
}));
