import React from 'react';

import { MenuItem } from '@mui/material';

import { SelectControl, SelectCaption, SelectField } from './Select.styled';

type SelectProps<T> = {
  name: string;
  options: string[] | T[];
  label?: string;
  required?: boolean;
  disabled?: boolean;
  value?: string | T;
  defaultValue?: string | T;
  getItemLabel?: (item: string | T) => string;
  getItemValue?: (item: string | T) => any;
  getItemKey?: (item: string | T, index: number) => string;
  onChange?: (value: T) => void;
};

export const Select: React.FC<SelectProps<any>> = ({
  name,
  options,
  label,
  value,
  defaultValue,
  required = false,
  disabled = false,
  getItemLabel = (item) => item,
  getItemValue = (item) => item,
  getItemKey = (item, index) => index,
  onChange = () => {},
}) => {
  return (
    <SelectControl
      variant="outlined"
      size="small"
      required={required}
      disabled={disabled}
    >
      <SelectCaption shrink={true}>{label}</SelectCaption>
      <SelectField
        name={name}
        value={value}
        defaultValue={defaultValue}
        required={required}
        disabled={disabled}
        onChange={(event) => onChange(event.target.value)}
      >
        {options.map((option, index) => (
          <MenuItem
            key={getItemKey(option, index)}
            value={getItemValue(option)}
          >
            {getItemLabel(option)}
          </MenuItem>
        ))}
      </SelectField>
    </SelectControl>
  );
};

export default Select;
