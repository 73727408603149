import React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { LogoTypeEnum } from 'client/components/Logo/enums/logo.enum';

export const LogoContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'type',
})<{
  type?: string;
}>(({ theme, type }) => ({
  display: 'flex',
  width: '100%',
  paddingBottom: 25,
  paddingTop: 14,
  paddingRight: 40,
  paddingLeft: 15,
  justifyContent: 'flex-start',
  alignItems: 'center',
  ...(type === LogoTypeEnum.Secondary && {
    justifyContent: 'flex-end',
  }),
  '& .MuiListItemIcon-root': {
    minWidth: 70,
    transition: '0.5s',
    paddingLeft: 0,
    ...(type === LogoTypeEnum.Secondary && {
      minWidth: 40,
    }),
  },
  '& .MuiTypography-root': {
    color: theme.palette.secondary.main,
    letterSpacing: 5,
    fontWeight: 600,
    paddingLeft: 15,
    ...(type === LogoTypeEnum.Secondary && {
      color: theme.palette.logo.main,
    }),
  },
}));
