export enum RoutesEnum {
  Login = '/login',
  Register = '/register',
  ForgotPassword = '/forgot-password',
  Dashboard = '/dashboard',
  Admin = '/admins',
  AdminDetails = '/admins/:id',
  Users = '/users',
  UserDetails = '/users/:id',
  Orders = '/orders',
  OrdersDetails = '/orders/:id',
  Profile = '/profile',
  Settings = '/settings',
  Clients = '/clients',
  ClientDetails = '/clients/:id',
  Sims = '/sims',
  SimsDetails = '/sims/:sid',
  Fleets = '/fleets',
  FleetDetails = '/fleets/:sid',
  NetworkAccessProfileDetails = '/network-access-profiles/:sid',
  NetworkAccessProfiles = '/network-access-profiles',
  Billing = '/billing',
  Reports = '/reports',
  Main = '/',
  TransactionLogs = "/transaction-logs",
}
