import React from 'react';

import { DateFormat, DateFormatEnum } from 'client/components';
import { LogoTypeEnum } from 'client/components/Logo/enums/logo.enum';
import { Logo } from 'client/components/Logo/Logo';
import constants from 'client/config';
import { Order } from 'client/services/order/types/order';

import { InvoiceMainInfo } from './components/InvoiceMainInfo/InvoiceMainInfo';
import { InvoiceTable } from './components/InvoiceTable/InvoiceTable';
import { InvoiceTotal } from './components/InvoiceTotal/InvoiceTotal';
import {
  InnerModalContainer,
  InnerModalHeadingContainer,
  HeadingTitle,
  PrintButton,
  InnerModalInfoContainer,
  ModalMainContainer,
  NestedModalInfoContainer,
  InvoiceInnerHeadingContainer,
  InvoiceNumberContainer,
  InvoiceLogoContainer,
  InvoiceNumberUpperContainer,
  InvoiceNumber,
  OrderStatusSuccess,
  OrderStatusError,
  InvoicerNumberInnerContainer,
  InvoiceDateContainer,
  InvoiceDate,
  InvoiceFooter,
  EmailText,
  GratitudeText,
} from './Invoice.styled';

type OrdersItemProps = {
  orderData: Order;
};
export const Invoice: React.FC<OrdersItemProps> = ({ orderData }) => {
  return (
    <ModalMainContainer>
      <InnerModalContainer>
        <InnerModalHeadingContainer>
          <HeadingTitle variant="h1">Your Invoice</HeadingTitle>
          <PrintButton onClick={() => window.print()} variant="contained">
            Print Invoice
          </PrintButton>
        </InnerModalHeadingContainer>
        <InnerModalInfoContainer>
          <NestedModalInfoContainer>
            <InvoiceInnerHeadingContainer>
              <InvoiceNumberContainer>
                <InvoiceNumberUpperContainer>
                  <InvoicerNumberInnerContainer>
                    <InvoiceNumber variant="h2">Invoice</InvoiceNumber>
                    {orderData.status === 'ordered' ? (
                      <OrderStatusError>Pending payment</OrderStatusError>
                    ) : (
                      <OrderStatusSuccess>Paid in full</OrderStatusSuccess>
                    )}
                  </InvoicerNumberInnerContainer>
                  <InvoiceDateContainer>
                    <InvoiceDate variant="body2">
                      Invoice Date:{' '}
                      <DateFormat
                        dateFormat={DateFormatEnum.ShortDate}
                        date={orderData.createdAt}
                      />
                    </InvoiceDate>
                  </InvoiceDateContainer>
                </InvoiceNumberUpperContainer>
              </InvoiceNumberContainer>
              <InvoiceLogoContainer>
                <Logo type={LogoTypeEnum.Secondary} />
              </InvoiceLogoContainer>
            </InvoiceInnerHeadingContainer>
            <InvoiceMainInfo orderData={orderData} />
            <InvoiceTable orderData={orderData} />
            <InvoiceTotal orderData={orderData} />
            <InvoiceFooter>
              <GratitudeText>Thank you for your business!</GratitudeText>
              <EmailText>{constants.company.email}</EmailText>
            </InvoiceFooter>
          </NestedModalInfoContainer>
        </InnerModalInfoContainer>
      </InnerModalContainer>
    </ModalMainContainer>
  );
};
