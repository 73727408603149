import React from 'react';

import { styled, Box, Button, Typography } from '@mui/material';

export const ModalMainContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const InnerModalContainer = styled(Box)(() => ({
  maxWidth: 916,
  width: '100%',
  '@media print': {
    maxWidth: '100%',
  },
}));

export const InnerModalHeadingContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 25,
  '@media print': {
    display: 'none',
  },
}));
export const HeadingTitle = styled(Typography)(({ theme }) => ({
  fontSize: 35,
  fontWeight: 600,
  color: theme.palette.common.black,
  marginBottom: 0,
}));

export const PrintButton = styled(Button)(() => ({
  textTransform: 'uppercase',
  fontSize: 14,
}));

export const InnerModalInfoContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,

  border: 'solid 1px #7f8fa4',
  '@media print': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10000,
    border: 'none',
    left: 0,
    top: 0,
  },
}));

export const NestedModalInfoContainer = styled(Box)(() => ({
  marginLeft: 60,
  marginRight: 60,
  marginBottom: 54,
  marginTop: 58,
  '@media print': {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    marginTop: 0,
  },
}));

export const InvoiceInnerHeadingContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
}));

export const InvoiceNumberContainer = styled(Box)(() => ({
  maxWidth: 411,
  width: '100%',
}));

export const InvoiceLogoContainer = styled(Box)(() => ({
  maxWidth: 350,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const InvoiceNumberUpperContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const InvoicerNumberInnerContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
}));
export const InvoiceNumber = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  color: theme.palette.common.black,
  fontWeight: 'bold',
  marginBottom: 0,
  letterSpacing: 1.5,
  '@media print': {
    fontSize: 20,
  },
}));

export const OrderStatusSuccess = styled(Box)(({ theme }) => ({
  textTransform: 'uppercase',
  border: `solid 2px ${theme.palette.success.main}`,
  color: theme.palette.success.dark,
  borderRadius: 4,
  fontWeight: 'bold',
  paddingTop: 6,
  paddingBottom: 6,
  paddingLeft: 20,
  paddingRight: 20,
}));

export const OrderStatusError = styled(OrderStatusSuccess)(({ theme }) => ({
  border: `solid 2px ${theme.palette.error.main}`,
  color: theme.palette.error.main,
  whiteSpace: 'nowrap',
}));

export const OrderStatus = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.success.dark,
  fontSize: 14,
  letterSpacing: 0.7,
}));

export const InvoiceDateContainer = styled(Box)(() => ({
  maxWidth: 411,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: 10,
}));

export const InvoiceDate = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.common.black,
  letterSpacing: 0.32,
}));

export const InfoDivider = styled(Box)(({ theme }) => ({
  width: '100%',
  opacity: 0.5,
  backgroundColor: theme.palette.divider,
  height: 1,
}));

export const Info = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.common.black,
  letterSpacing: 0.6,
  fontWeight: 'normal',
  lineHeight: 1.5,
  whiteSpace: 'nowrap',
}));

export const InvoiceFooter = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: 250,
  '@media print': {
    marginTop: 150,
  },
}));

export const GratitudeText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 600,
  color: theme.palette.common.black,
}));

export const EmailText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.common.black,
}));
