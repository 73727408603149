import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useStateWithDep = <T extends unknown>(
  defaultValue: T,
): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  return [value, setValue];
};
