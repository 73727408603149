import { FormControl, InputLabel, Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SelectControl = styled(FormControl)(() => ({
  width: 270,
  marginTop: 20,
}));

export const SelectCaption = styled(InputLabel)(() => ({
  fontSize: 12,
  lineHeight: 1,
  textTransform: 'capitalize',
  marginBottom: 7,
  position: 'static',
  transform: 'none',
}));

export const SelectField = styled(Select)(() => ({
  '& .MuiSvgIcon-root.MuiSelect-icon': {
    color: '#4558f2',
  },
}));
