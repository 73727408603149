import React from 'react';
import NumberFormat, { NumberFormatPropsBase } from 'react-number-format';

interface CurrencyFormatProps extends NumberFormatPropsBase {
  amount: number;
  cents?: boolean;
}

export const FormatCurrency = (
  { amount = 0, cents = false } = {} as CurrencyFormatProps,
) => {
  return cents ? amount / 100 : amount;
};

export const CurrencyFormat = (
  props: CurrencyFormatProps,
): React.ReactElement => {
  return (
    <NumberFormat
      value={FormatCurrency(props)}
      displayType="text"
      prefix="$"
      thousandSeparator=" "
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
};

export default CurrencyFormat;
