import React from 'react';

import { Label } from 'client/components/Label/Label';

import { HeadingWrapper, HeadingDivider } from './Heading.styled';

type HeadingProps = {
  label: string;
};

export const Heading: React.FC<HeadingProps> = ({ label }) => {
  return (
    <HeadingWrapper>
      <Label variant="h5">{label}</Label>
      <HeadingDivider />
    </HeadingWrapper>
  );
};

export default Heading;
