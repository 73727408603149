import React from 'react';

import { RolesEnum } from 'client/services/roles/enums/roles.enum';

import { RoleGroupsEnum } from './enums/role-groups.enum';
import usePermission from './hooks/usePermission';

type Props = {
  role: RolesEnum | RoleGroupsEnum;
  fallback?: JSX.Element | string;
};

const Permission: React.FC<Props> = ({ role, fallback, children }) => {
  const { hasRole } = usePermission();

  if (hasRole(role)) return <>{children}</>;

  return <>{fallback}</>;
};

export default Permission;
