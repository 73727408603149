import { Box, Typography, Input, Link, NativeSelect } from '@mui/material';
import { styled } from '@mui/material/styles';
type FontSizeProps = {
  fontSize: number;
};
export const RecordEditableWrapper = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 20,
  marginRight: 20,
}));

export const RecordEditableLabel = styled(Typography)<FontSizeProps>(
  ({ theme, fontSize }) => ({
    color: theme.palette.secondary.main,
    textTransform: 'capitalize',
    fontSize: fontSize,
  }),
);

export const RecordEditableValue = styled(Box)(() => ({}));

export const RecordEditableContent = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  height: 18,
}));

export const RecordEditableText = styled(Typography)<FontSizeProps>(
  ({ theme, fontSize }) => ({
    color: theme.palette.secondary.main,
    lineHeight: 1,
    fontSize,
  }),
);

export const RecordEditableInput = styled(Input)(() => ({
  display: 'flex',
  height: 18,
  boxSizing: 'border-box',
}));

export const RecordEditableSelect = styled(NativeSelect)(() => ({
  display: 'flex',
  height: 18,
  boxSizing: 'border-box',
  fontSize: 12,
  '& select:focus': {
    backgroundColor: 'transparent',
  },
}));

export const RecordEditableButton = styled(Link)(() => ({
  marginLeft: 4,
  lineHeight: 1,
}));
