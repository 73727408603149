import React from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';

import loadable from '@loadable/component';

import { Layout, Loading } from 'client/components';
import { RoleGroupsEnum } from 'client/components/Permission/enums/role-groups.enum';
import usePermission from 'client/components/Permission/hooks/usePermission';
import {
  AuthRouteProps,
  AuthRoutes,
  UnAuthRouteProps,
  UnAuthRoutes,
} from 'client/config/routeGroups';
import { RoutesEnum } from 'client/enums/routes.enum';
import useNotification from 'client/hooks/useNotification';

import { GuardRoute } from '../components/GuardRoute/GuardRoute';
import useAuthContext from '../hooks/useAuthContext';
import { StatusUserEnum } from '../services/user/types/user.type';

const getPageComponent = (page: string, screen?: string) => {
  return loadable(
    () =>
      import(
        `client/pages/${page}/${
          screen ? `components/${screen}/${screen}` : `${page}`
        }`
      ),
    {
      fallback: <Loading />,
    },
  );
};

function AuthenticatedRoute(props: RouteProps) {
  const { user } = useAuthContext();
  const { errorCode } = useNotification();
  if (Object.keys(user).length === 0 || errorCode === 401)
    return <Redirect to={RoutesEnum.Login} />;
  if (
    user.status === StatusUserEnum.Inactive ||
    user.client.status === StatusUserEnum.Blocked
  )
    return (
      <>
        <Redirect to={RoutesEnum.Billing} />
        <Route {...props} />
      </>
    );
  return <Route {...props} />;
}

function UnAuthenticatedRoute(props: RouteProps) {
  const { user } = useAuthContext();
  const { errorCode } = useNotification();
  const { hasRole } = usePermission();
  if (Object.keys(user).length && errorCode !== 401)
    return (
      <Redirect
        to={
          hasRole(RoleGroupsEnum.Admin)
            ? RoutesEnum.Clients
            : RoutesEnum.Dashboard
        }
      />
    );

  return <Route {...props} />;
}

const Routes = (): React.ReactElement => (
  <Switch>
    <AuthenticatedRoute exact path={AuthRouteProps}>
      <Layout>
        <Switch>
          {AuthRoutes.map((route) => {
            return (
              <GuardRoute
                key={route.page}
                fallBackPath={RoutesEnum.Main}
                allowedForRoles={route.allowedForRoles}
                component={getPageComponent(route.page, route.screen)}
                {...route.routeProps}
              />
            );
          })}
        </Switch>
      </Layout>
    </AuthenticatedRoute>
    <UnAuthenticatedRoute path={UnAuthRouteProps}>
      <Switch>
        {UnAuthRoutes.map((route) => {
          return (
            <Route
              key={route.page}
              component={getPageComponent(route.page, route.screen)}
              {...route.routeProps}
            />
          );
        })}
      </Switch>
    </UnAuthenticatedRoute>
    <Redirect path="*" exact to={RoutesEnum.Main} />
  </Switch>
);

export default Routes;
