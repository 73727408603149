import React from 'react';

import {
  MasterCard as MasterCardIcon,
  Visa as VisaIcon,
} from 'client/themes/icons';

export enum CardEnum {
  Visa = 'visa',
  MasterCard = 'mastercard',
}
type PaymentCardProps = {
  cardType: CardEnum;
};
export const BalanceCardType: React.FC<PaymentCardProps> = ({ cardType }) => {
  const { Visa, MasterCard } = CardEnum;
  const cardTypeConfig = {
    [Visa]: <VisaIcon />,
    [MasterCard]: <MasterCardIcon />,
  };
  return cardTypeConfig[cardType];
};

export default BalanceCardType;
