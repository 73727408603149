import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { ReactComponent as MasterCardIcon } from './MasterCard.svg';

export const MasterCard: React.FC = ({
  sx = { height: 25, width: 30, marginRight: '5px' },
  viewBox = '0 0 24 24',
  ...iconProps
}: SvgIconProps) => (
  <SvgIcon
    component={MasterCardIcon}
    sx={sx}
    viewBox={viewBox}
    {...iconProps}
  />
);
