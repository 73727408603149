import React from 'react';

import BalanceCardType, {
  CardEnum,
} from 'client/pages/Billing/components/BalanceCardType/BalanceCardType';
import { BillingInfoParams } from 'client/services/payment/types/billing-info.type';

import {
  SavedPaymentMethodContainer,
  SavedPaymentMethodContent,
  SavedPaymentMethodCardInfo,
  SavedPaymentMethodCardText,
} from './SavedPaymentMethod.styled';

type SavedPaymentMethodData = {
  method: BillingInfoParams;
};

export const SavedPaymentMethod: React.FC<SavedPaymentMethodData> = ({
  method,
}) => {
  return (
    <SavedPaymentMethodContainer paddingX={0}>
      <SavedPaymentMethodContent>
        <SavedPaymentMethodCardInfo>
          <BalanceCardType cardType={method.card?.brand as CardEnum} />
          <SavedPaymentMethodCardText paddingRight={2}>
            **** **** **** {method.card?.last4}
          </SavedPaymentMethodCardText>
          <SavedPaymentMethodCardText>
            {method.card?.exp_month}/{method.card?.exp_year}
          </SavedPaymentMethodCardText>
        </SavedPaymentMethodCardInfo>
      </SavedPaymentMethodContent>
    </SavedPaymentMethodContainer>
  );
};

export default SavedPaymentMethod;
