import {
  Paper,
  PaperProps,
  SvgIconProps,
  IconButton,
  DialogContent,
  SvgIcon,
} from '@mui/material';
import { styled } from '@mui/material/styles';

type PopupContainerProps = PaperProps & {
  type?: 'content' | 'fullscreen';
  background?: 'dark' | 'light';
};
type PopupIconProps = SvgIconProps & {
  stroke?: 'dark' | 'light';
  component: React.ElementType;
};

export const PopupContainer = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'type' && prop !== 'background',
})<PopupContainerProps>(({ theme, type, background }) => ({
  padding: 45,
  '&.MuiDialog-paper': {
    ...(type === 'fullscreen' && {
      margin: 15,
      maxWidth: 'calc(100% - 30px)',
      width: 'calc(100% - 30px)',
      '@media print': {
        display: 'block',
        position: 'fixed',
      },
    }),
    ...(background === 'light' && {
      backgroundColor: theme.palette.background.modal,
    }),
    [theme.breakpoints.down('sm')]: {
      padding: 25,
    },
  },
}));

export const PopupClose = styled(IconButton)(() => ({
  position: 'absolute',
  top: 10,
  right: 10,
  '@media print': {
    display: 'none',
  },
}));

export const PopupIcon = styled(SvgIcon, {
  shouldForwardProp: (prop) => prop !== 'stroke',
})<PopupIconProps>(({ stroke, theme }) => ({
  ...(stroke === 'light' && {
    color: theme.palette.common.black,
  }),
}));

export const PopupContent = styled(DialogContent)(() => ({
  padding: 0,
  overflow: 'visible',
}));
