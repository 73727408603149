import { TabPanel } from '@mui/lab';
import { Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FilledTabsContainer = styled(Tabs)(({ theme }) => ({
  borderRadius: 4,
  width: 'fit-content',
  height: 40,
  backgroundColor: theme.palette.background.container,
  borderBottomWidth: 0,
}));

export const FilledTab = styled(Tab)(({ theme }) => ({
  paddingLeft: 32,
  paddingRight: 32,
  paddingBottom: 0,
  paddingTop: 0,
  textTransform: 'capitalize',
  borderBottomWidth: 0,
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
    borderRadius: 4,
  },
}));

export const TabsUnderlinedPanel = styled(TabPanel)(() => ({
  paddingTop: 40,
  paddingLeft: 0,
  paddingRight: 0,
  '& .MuiBox-root': {
    marginBottom: 0,
  },
}));
