import React from 'react';

import { useTheme } from '@mui/material/styles';
import { CardElement } from '@stripe/react-stripe-js';

import { PaymentCardInputWrapper } from './PaymentCardInput.styled';

type PaymentCardInputProps = {
  className?: string;
};

export const PaymentCardInput: React.FC<PaymentCardInputProps> = ({
  className,
}) => {
  const theme = useTheme();

  const style = {
    base: {
      color: theme.palette.secondary.main,
      fontSize: '14px',
      iconColor: theme.palette.secondary.main,
      ':focus': {
        color: theme.palette.secondary.main,
      },
      '::placeholder': {
        color: theme.palette.secondary.main,
      },
    },
    invalid: {
      color: theme.palette.error.main,
      iconColor: theme.palette.error.main,
    },
  };

  return (
    <PaymentCardInputWrapper className={className}>
      <CardElement options={{ style }} />
    </PaymentCardInputWrapper>
  );
};

export default PaymentCardInput;
