import React from 'react';

import { TypographyPropsVariantOverrides } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { OverridableStringUnion } from '@mui/types';

import { LabelText } from './Label.styled';

type LabelProps = {
  variant?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >;
};

export const Label: React.FC<LabelProps> = ({ children, variant = 'h5' }) => {
  return <LabelText variant={variant}>{children}</LabelText>;
};

export default Label;
