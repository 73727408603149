import React from 'react';

import { ReactComponent as MoonIcon } from './icons/moon.svg';
import { ReactComponent as SunIcon } from './icons/sun.svg';
import {
  ThemeTogglerIcons,
  ThemeTogglerInput,
  ThemeTogglerItem,
  ThemeTogglerIcon,
  ThemeTogglerWrapper,
} from './ThemeToggler.styled';

type ThemeTogglerProps = {
  checked: boolean;
  onChange: (enabled: boolean) => void;
};

export const ThemeToggler: React.FC<ThemeTogglerProps> = ({
  checked,
  onChange,
}) => {
  const toggleState = () => {
    onChange(!checked);
  };

  return (
    <ThemeTogglerWrapper htmlFor="app-theme-toggler">
      <ThemeTogglerItem checked={checked}>
        <ThemeTogglerIcons>
          <ThemeTogglerIcon component={SunIcon} />
          <ThemeTogglerIcon component={MoonIcon} />
        </ThemeTogglerIcons>
        <ThemeTogglerInput
          id="app-theme-toggler"
          name="app-theme-toggler"
          checked={checked}
          onClick={toggleState}
        />
      </ThemeTogglerItem>
    </ThemeTogglerWrapper>
  );
};
