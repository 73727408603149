import React from 'react';

import { ListItemIcon, Typography } from '@mui/material';

import { LogoTypeEnum } from 'client/components/Logo/enums/logo.enum';
import { ReactComponent as LogoIcon } from 'client/components/Logo/logo.svg';

import { LogoContainer } from './Logo.styled';

type IconProps = {
  type: LogoTypeEnum.Secondary | LogoTypeEnum.Primary;
};

export const Logo: React.FC<IconProps> = ({ type }) => {
  return (
    <LogoContainer type={type}>
      <ListItemIcon>
        <LogoIcon />
      </ListItemIcon>
      <Typography>ULTRAVIOLET</Typography>
    </LogoContainer>
  );
};
