import React from 'react';

import { Backdrop, CircularProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

type LoadingProps = {
  sx?: SxProps<Theme>;
};

export const Loading: React.FC<LoadingProps> = ({
  sx = { zIndex: (theme) => theme.zIndex.drawer - 1 },
}) => (
  <Backdrop sx={sx} open>
    <CircularProgress />
  </Backdrop>
);

export default Loading;
