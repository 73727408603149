import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Divider, Tab } from '@mui/material';

import { TabsTypeEnum } from './enums/tab-type.enum';
import {
  FilledTab,
  FilledTabsContainer,
  TabsUnderlinedPanel,
} from './Tabs.styled';

type TabsProps = {
  labels: string[];
  selectedTab: string;
  items: ReactElement[];
  type?: TabsTypeEnum;
  onChange?: (tab: string) => void;
};

type TabContainerProps = {
  children: ReactElement[];
};

type TabLabelProps = {
  key: number;
  label?: string;
  value: string;
};

type TabContentProps = {
  key: number;
  label?: string;
  value: string;
  children?: ReactElement;
};

const { Underlined, Filled } = TabsTypeEnum;

export const Tabs: FC<TabsProps> = ({
  labels,
  items,
  selectedTab,
  type = Filled,
  onChange,
}) => {
  const [tab, setTab] = useState<string>(selectedTab);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, value: string) => {
      setTab(value);
      onChange && onChange(value);
    },
    [onChange],
  );

  const tabConfig = useMemo(
    () => ({
      [Filled]: {
        tabContainer: (props: TabContainerProps) => (
          <FilledTabsContainer
            value={tab}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
            {...props}
          />
        ),
        tab: (props: TabLabelProps) => <FilledTab {...props} />,
        tabPanel: (props: TabContentProps) => <TabPanel {...props} />,
      },
      [Underlined]: {
        tabContainer: (props: TabContainerProps) => (
          <TabList value={tab} onChange={handleChange} {...props} />
        ),
        tab: (props: TabLabelProps) => <Tab {...props} />,
        tabPanel: (props: TabContentProps) => (
          <TabsUnderlinedPanel {...props} />
        ),
      },
    }),
    [handleChange, tab],
  );

  const TabContainer = useCallback(
    (props: TabContainerProps) => tabConfig[type].tabContainer(props),
    [tabConfig, type],
  );

  const TabContent = useCallback(
    (props: TabContentProps) => tabConfig[type].tabPanel(props),
    [tabConfig, type],
  );

  const TabLabel = useCallback(
    (props: TabLabelProps) => tabConfig[type].tab(props),
    [tabConfig, type],
  );

  useEffect(() => {
    setTab(selectedTab);
  }, [selectedTab]);

  return useMemo(
    () => (
      <TabContext value={tab}>
        <TabContainer>
          {labels.map((label, index) => (
            <TabLabel key={index} label={label} value={index.toString()} />
          ))}
        </TabContainer>
        {type === Underlined && <Divider />}
        {items.map((item, index) => (
          <TabContent key={index} value={index.toString()}>
            {item}
          </TabContent>
        ))}
      </TabContext>
    ),
    [TabContainer, TabContent, TabLabel, items, labels, tab, type],
  );
};

export default Tabs;
