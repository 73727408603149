import React from 'react';

import { Alert, Snackbar, Typography } from '@mui/material';

import useNotification from 'client/hooks/useNotification';

export const Notification = (): React.ReactElement => {
  const { notification, clearNotification } = useNotification();
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;

    clearNotification(notification.type);
  };
  return (
    <Snackbar
      open={!!notification.message}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={notification.type}
        sx={{ minWidth: 300, width: '100%' }}
      >
        <Typography variant="body1">{notification.message}</Typography>
      </Alert>
    </Snackbar>
  );
};

export default Notification;
