import React, { FC, useCallback, useMemo, useState } from 'react';

import { ConfirmDialog } from '../components/ConfirmationDialog/ConfirmationDialog';

type OnSubmitType = () => void;
type UseConfirmationDialogReturnType = {
  ConfirmationDialog: FC;
  checkConfirmation: (message: string, onSubmit: OnSubmitType) => void;
};

export const useConfirmationDialog = (): UseConfirmationDialogReturnType => {
  const [open, setOpen] = useState<boolean>(false);
  const [{ message, onSubmit }, setDialogMeta] = useState<{
    message: string;
    onSubmit: OnSubmitType;
  }>({ message: '', onSubmit: () => {} });

  const ConfirmationDialog: FC = useCallback(
    () => (
      <ConfirmDialog
        open={open}
        message={message}
        onClose={() => setOpen(false)}
        onConfirm={() => {
          setOpen(false);
          onSubmit();
        }}
      />
    ),
    [open],
  );

  const checkConfirmation = useCallback(
    (message: string, onSubmit: OnSubmitType) => {
      setDialogMeta({ message, onSubmit });
      setOpen(true);
    },
    [],
  );

  return useMemo(
    () => ({
      ConfirmationDialog,
      checkConfirmation,
    }),
    [ConfirmationDialog, checkConfirmation],
  );
};
