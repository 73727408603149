import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import {
  Groups as GroupsIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
} from '@mui/icons-material';
import { ListItem, SvgIcon } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';

import { DrawerProps as SidebarProps } from 'client/components/Layout/Layout';
import { LogoTypeEnum } from 'client/components/Logo/enums/logo.enum';
import { Logo } from 'client/components/Logo/Logo';
import { ReactComponent as FleetsIcon } from 'client/components/Sidebar/assets/fleets.svg';
import { ReactComponent as HomeIcon } from 'client/components/Sidebar/assets/home.svg';
import { ReactComponent as NetworkAccessProfilesIcon } from 'client/components/Sidebar/assets/network-access-profiles.svg';
import { ReactComponent as OrdersIcon } from 'client/components/Sidebar/assets/orders.svg';
import { ReactComponent as ReportsIcon } from 'client/components/Sidebar/assets/reports.svg';
import { ReactComponent as SimIcon } from 'client/components/Sidebar/assets/sim.svg';
import { RoutesEnum } from 'client/enums/routes.enum';
import useAuthContext from 'client/hooks/useAuthContext';
import { RolesEnum } from 'client/services/roles/enums/roles.enum';
import { StatusUserEnum } from 'client/services/user/types/user.type';

import { RoleGroupsEnum } from '../Permission/enums/role-groups.enum';
import Permission from '../Permission/Permissions';
import SidebarListItemButton from '../SidebarListItemButton/SidebarListItemButton';
import {
  SidebarDrawer,
  SidebarList,
  SidebarDivider,
  SidebarListItemIcon,
  SidebarListItemText,
  SidebarAnimations,
} from './Sidebar.styled';
export const Sidebar: React.FC<SidebarProps> = (props) => {
  const { user } = useAuthContext();
  const { isOpen, toggleDrawer } = props;
  const [drawerAnimation, setDrawerAnimation] = useState('default');
  return (
    <SidebarDrawer
      onMouseOver={() =>
        drawerAnimation !== SidebarAnimations.Enter &&
        setDrawerAnimation(SidebarAnimations.Enter)
      }
      onMouseLeave={() => setDrawerAnimation(SidebarAnimations.Out)}
      isOpen={isOpen}
      drawerAnimation={drawerAnimation}
      variant="permanent"
    >
      <Logo type={LogoTypeEnum.Primary} />
      <SidebarList
        disabled={user.client.status === StatusUserEnum.Blocked}
        disablePadding
      >
        <ListItem disablePadding>
          <SidebarListItemButton
            onClick={() => toggleDrawer(false)}
            exact
            component={NavLink}
            to={RoutesEnum.Main}
          >
            <SidebarListItemIcon>
              <SvgIcon component={HomeIcon} />
            </SidebarListItemIcon>
            <SidebarListItemText disableTypography primary="Dashboard" />
          </SidebarListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <SidebarListItemButton
            onClick={() => toggleDrawer(false)}
            exact
            component={NavLink}
            to={RoutesEnum.TransactionLogs}
          >
            <SidebarListItemIcon>
              <SvgIcon component={HistoryIcon} />
            </SidebarListItemIcon>
            <SidebarListItemText disableTypography primary="Transactions" />
          </SidebarListItemButton>
        </ListItem>

        <Permission role={RolesEnum.AdminTeamSuperAdmin}>
          <ListItem disablePadding>
            <SidebarListItemButton
              onClick={() => toggleDrawer(false)}
              component={NavLink}
              to={RoutesEnum.Admin}
            >
              <SidebarListItemIcon>
                <GroupsIcon />
              </SidebarListItemIcon>
              <SidebarListItemText disableTypography primary="Admins" />
            </SidebarListItemButton>
          </ListItem>
        </Permission>
        <Permission role={RolesEnum.ClientTeamAdmin}>
          <ListItem disablePadding>
            <SidebarListItemButton
              onClick={() => toggleDrawer(false)}
              component={NavLink}
              to={RoutesEnum.Users}
            >
              <SidebarListItemIcon>
                <GroupsIcon />
              </SidebarListItemIcon>
              <SidebarListItemText disableTypography primary="Users" />
            </SidebarListItemButton>
          </ListItem>
        </Permission>
        <Permission role={RoleGroupsEnum.Admin}>
          <ListItem disablePadding>
            <SidebarListItemButton
              onClick={() => toggleDrawer(false)}
              component={NavLink}
              to={RoutesEnum.Clients}
            >
              <SidebarListItemIcon>
                <GroupsIcon />
              </SidebarListItemIcon>
              <SidebarListItemText disableTypography primary="Clients" />
            </SidebarListItemButton>
          </ListItem>
        </Permission>
        <ListItem disablePadding>
          <SidebarListItemButton
            onClick={() => toggleDrawer(false)}
            component={NavLink}
            to={RoutesEnum.Orders}
          >
            <SidebarListItemIcon>
              <SvgIcon component={OrdersIcon} />
            </SidebarListItemIcon>
            <SidebarListItemText disableTypography primary="Orders" />
          </SidebarListItemButton>
        </ListItem>
        <Permission role={RoleGroupsEnum.Client}>
          <ListItem disablePadding>
            <SidebarListItemButton
              onClick={() => toggleDrawer(false)}
              component={NavLink}
              to={RoutesEnum.Fleets}
            >
              <SidebarListItemIcon>
                <SvgIcon component={FleetsIcon} />
              </SidebarListItemIcon>
              <SidebarListItemText disableTypography primary="Fleets" />
            </SidebarListItemButton>
          </ListItem>
        </Permission>
        <Permission role={RolesEnum.AdminTeamSuperAdmin}>
          <ListItem disablePadding>
            <SidebarListItemButton
              onClick={() => toggleDrawer(false)}
              component={NavLink}
              to={RoutesEnum.NetworkAccessProfiles}
            >
              <SidebarListItemIcon>
                <SvgIcon component={NetworkAccessProfilesIcon} />
              </SidebarListItemIcon>
              <SidebarListItemText
                disableTypography
                primary="Network Access Profiles"
              />
            </SidebarListItemButton>
          </ListItem>
        </Permission>
        <Permission role={RolesEnum.ClientTeamAdmin}>
          <ListItem disablePadding>
            <SidebarListItemButton
              onClick={() => toggleDrawer(false)}
              component={NavLink}
              to={RoutesEnum.Billing}
            >
              <SidebarListItemIcon>
                <AccountBalanceWalletIcon />
              </SidebarListItemIcon>
              <SidebarListItemText disableTypography primary="Billing" />
            </SidebarListItemButton>
          </ListItem>
        </Permission>
      </SidebarList>
      <SidebarDivider />
      <SidebarList
        disabled={user.client.status === StatusUserEnum.Blocked}
        disablePadding
      >
        <ListItem disablePadding>
          <SidebarListItemButton
            onClick={() => toggleDrawer(false)}
            component={NavLink}
            to={RoutesEnum.Reports}
          >
            <SidebarListItemIcon>
              <SvgIcon component={ReportsIcon} />
            </SidebarListItemIcon>
            <SidebarListItemText disableTypography primary="Reports" />
          </SidebarListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <SidebarListItemButton
            onClick={() => toggleDrawer(false)}
            component={NavLink}
            to={RoutesEnum.Sims}
          >
            <SidebarListItemIcon>
              <SvgIcon component={SimIcon} />
            </SidebarListItemIcon>
            <SidebarListItemText disableTypography primary="SIM Inventory" />
          </SidebarListItemButton>
        </ListItem>
      </SidebarList>
    </SidebarDrawer>
  );
};
