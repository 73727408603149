import React from 'react';
import { RouteComponentProps, RouteProps } from 'react-router';
import { Route, Redirect } from 'react-router-dom';

import { RolesEnum } from 'client/services/roles/enums/roles.enum';

import { RoleGroupsEnum } from '../Permission/enums/role-groups.enum';
import usePermission from '../Permission/hooks/usePermission';

export interface PrivateRouteProps extends RouteProps {
  fallBackPath: string;
  allowedForRoles?: (RolesEnum | RoleGroupsEnum)[];
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export const GuardRoute: React.FC<PrivateRouteProps> = ({
  fallBackPath,
  allowedForRoles,
  ...rest
}) => {
  const { hasRole } = usePermission();

  if (allowedForRoles && !allowedForRoles.some((role) => hasRole(role)))
    return <Redirect to={fallBackPath} />;

  return <Route {...rest} />;
};
