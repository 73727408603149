import { styled, FormControl, InputLabel, OutlinedInput } from '@mui/material';

export const InputControl = styled(FormControl)(() => ({
  width: 270,
  marginTop: 20,
}));

export const InputCaption = styled(InputLabel)(() => ({
  fontSize: 12,
  lineHeight: 1,
  textTransform: 'capitalize',
  marginBottom: 7,
  position: 'static',
  transform: 'none',
}));

export const InputField = styled(OutlinedInput)(() => ({}));
