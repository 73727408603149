import { styled, Divider, Box } from '@mui/material';

export const HeadingWrapper = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
}));

export const HeadingDivider = styled(Divider)(() => ({
  flexGrow: 1,
  marginLeft: 5,
}));
