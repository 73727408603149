import React from 'react';

import { DateFormat, DateFormatEnum } from 'client/components';
import { Order } from 'client/services/order/types/order';

import {
  InvoiceTableWrapper,
  InvoiceTableContainer,
  TableInfoContainer,
  TableHeadingContainer,
  TableHeader,
  OrderColumnContainer,
  OrderColumnInnerContainer,
  ColumnText,
  ColumnContainer,
} from './InvoiceTable.styled';

type OrdersItemProps = {
  orderData: Order;
};

export const InvoiceTable: React.FC<OrdersItemProps> = ({ orderData }) => {
  return (
    <InvoiceTableWrapper>
      <InvoiceTableContainer>
        <TableInfoContainer>
          <TableHeadingContainer>
            <TableHeader>Order</TableHeader>
          </TableHeadingContainer>
          <OrderColumnContainer>
            <OrderColumnInnerContainer>
              <ColumnText>Order Date</ColumnText>
              <ColumnText>Order Number</ColumnText>
              <ColumnText>Delivery Method</ColumnText>
            </OrderColumnInnerContainer>
            <OrderColumnInnerContainer>
              <ColumnText>
                <DateFormat
                  dateFormat={DateFormatEnum.ShortDate}
                  date={orderData.createdAt}
                />
              </ColumnText>
              <ColumnText>103528</ColumnText>
              <ColumnText>{orderData.shippingService}</ColumnText>
            </OrderColumnInnerContainer>
          </OrderColumnContainer>
        </TableInfoContainer>
        <TableInfoContainer>
          <TableHeadingContainer>
            <TableHeader>Ship To</TableHeader>
          </TableHeadingContainer>
          <ColumnContainer>
            <ColumnText>{orderData.shippingClient}</ColumnText>
            <ColumnText>{orderData.shippingRecipient}</ColumnText>
            <ColumnText>{orderData.shippingAddressLine1}</ColumnText>
            <ColumnText>{orderData.shippingAddressLine1}</ColumnText>
          </ColumnContainer>
        </TableInfoContainer>
      </InvoiceTableContainer>
    </InvoiceTableWrapper>
  );
};
