import React from 'react';

import { ListItemButton, ListItemButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SidebarItemButton = styled(ListItemButton)<
  ListItemButtonProps & {
    component: React.ElementType;
    to: string;
    exact?: boolean;
  }
>(({ theme }) => ({
  paddingTop: 12,
  borderLeft: `3px solid ${theme.palette.background.paper}`,
  paddingRight: 20,
  paddingBottom: 12,
  paddingLeft: 20,
}));

export const UnactiveLink = styled(SidebarItemButton)(({ theme }) => ({
  '&.active': {
    borderLeft: `3px solid ${theme.palette.background.paper}`,
    '& .MuiSvgIcon-root': {
      fill: theme.palette.common.white,
    },
  },
}));
