import { styled, Box } from '@mui/material';

export const PaymentCardInputWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  borderColor: theme.palette.transparent.secondary,
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 4,
  paddingTop: 16,
  paddingRight: 14,
  paddingBottom: 16,
  paddingLeft: 14,
}));
