import React from 'react';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InvoiceTableWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.transparent.main,
  marginTop: 45,
  display: 'flex',
}));

export const InvoiceTableContainer = styled(Box)(() => ({
  marginTop: 14,
  marginLeft: 24,
  marginBottom: 21,
  marginRight: 24,
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}));

export const TableInfoContainer = styled(Box)(() => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
}));

export const TableHeadingContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
}));

export const TableHeader = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  letterSpacing: 0.8,
  color: theme.palette.common.black,
}));

export const ColumnContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
}));

export const OrderColumnContainer = styled(ColumnContainer)(() => ({
  flexDirection: 'row',
  width: '100%',
}));

export const OrderColumnInnerContainer = styled(ColumnContainer)(() => ({
  flexGrow: 1,
}));

export const ColumnText = styled(Typography)(({ theme }) => ({
  fontSize: 12,

  letterSpacing: 0.6,
  lineHeight: 2,
  color: theme.palette.common.black,
}));
