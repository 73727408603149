import React from 'react';

import { Link, Typography } from '@mui/material';

export const Copyright = (): React.ReactElement => (
  <Typography
    variant="body2"
    color="text.secondary"
    align="center"
    sx={{ mt: 8, mb: 4 }}
  >
    {'Copyright © '}
    <Link color="inherit" href="#">
      UltraViolet
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
);

export default Copyright;
