import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { ReactComponent as VisaIcon } from './Visa.svg';

export const Visa: React.FC = ({
  sx = { height: 48, width: 48, marginRight: '5px' },
  viewBox = '0 0 48 48',
  ...iconProps
}: SvgIconProps) => (
  <SvgIcon component={VisaIcon} sx={sx} viewBox={viewBox} {...iconProps} />
);
