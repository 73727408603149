import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { createTheme } from '@mui/material';

import MulishVariableFont from 'client/themes/fonts/Mulish-VariableFont_wght.ttf';

export const light = createTheme({
  typography: {
    fontFamily: 'Mulish, Roboto, Open Sans',
    h1: {
      color: '#00263e',
      fontSize: 32,
      lineHeight: 1.5,
      marginBottom: 18,
    },
    h2: {
      fontSize: 24,
      lineHeight: 1.25,
    },
    h3: {
      fontSize: 23,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h4: {
      fontSize: 18,
      lineHeight: 1.5,
    },
    h5: {
      fontSize: 14,
      lineHeight: 1.5,
    },
    body1: {
      color: '#9babc5',
      fontSize: 14,
      lineHeight: 1.5,
    },
    body2: {
      color: '#8390a0',
      fontSize: 12,
      lineHeight: 1.25,
    },
    subtitle1: {
      color: '#8390a0',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 1.25,
    },
    caption: {
      color: '#9babc5',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          transition: 'all 0.5s ease',
        },
        '@font-face': {
          fontFamily: 'Mulish',
          fontStyle: 'normal',
          fontDisplay: 'swap',
          src: `local('Mulish'), url(${MulishVariableFont}) format('truetype')`,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'highlighted' },
          style: {
            borderRadius: 0,
            borderColor: '#4558f2',
            borderWidth: 1,
            borderStyle: 'solid',
            color: '#000',
          },
        },
        {
          props: { variant: 'highlighted', size: 'medium' },
          style: {
            paddingTop: 13,
            paddingRight: 20,
            paddingBottom: 13,
            paddingLeft: 20,
            fontSize: 14,
            lineHeight: 1,
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderColor: '#313439',
            borderWidth: 1,
            borderStyle: 'solid',
            color: '#000',
            textTransform: 'none',
          },
        },
        {
          props: { variant: 'outlined', size: 'medium' },
          style: {
            paddingTop: 10,
            paddingRight: 20,
            paddingBottom: 10,
            paddingLeft: 20,
            borderRadius: 4,
            fontSize: 14,
            lineHeight: 1,
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            border: 'none',
            backgroundColor: '#4558f2',
            color: '#ffffff',
            textTransform: 'none',
          },
        },
        {
          props: { variant: 'contained', size: 'small' },
          style: {
            paddingTop: 10,
            paddingRight: 12,
            paddingBottom: 10,
            paddingLeft: 12,
            borderRadius: 4,
            fontSize: 14,
            lineHeight: 1,
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#4558f2',
          textDecoration: 'none',
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          opacity: 0.2,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          '& .MuiAlert-action': {
            paddingTop: 0,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownIcon,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          borderColor: '#313439',
          borderWidth: 1,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          paddingTop: 22,
          paddingBottom: 22,
          fontSize: 16,
          '&.Mui-selected': {
            color: '#000',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: '#00263e',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#e7eaee',
          '& .MuiTableCell-head': {
            paddingTop: 10,
            paddingRight: 15,
            paddingBottom: 10,
            paddingLeft: 15,
            borderBottom: 0,
            color: '#9babc5',
            fontSize: 14,
            lineHeight: 1.25,
            textTransform: 'uppercase',
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: '#f6f7f9',
          '& .MuiTableCell-body': {
            paddingTop: 10,
            paddingRight: 15,
            paddingBottom: 10,
            paddingLeft: 15,
            fontSize: 14,
            lineHeight: 1.25,
          },
          '& .MuiTableCell-body.MuiTableCell-paddingNone': {
            padding: 0,
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          backgroundColor: '#f6f7f9',
          '& .MuiTablePagination-toolbar': {
            paddingLeft: 20,
          },
          '& .MuiTablePagination-displayedRows': {
            fontSize: 14,
            lineHeight: 1,
            order: -1,
          },
          '& .MuiTablePagination-selectLabel': {
            paddingTop: 4,
            paddingBottom: 5,
            paddingLeft: 8,
            paddingRight: 8,
            color: '#000',
            fontSize: 14,
            lineHeight: 1.5,
          },
          '& .MuiTablePagination-input': {
            margin: 0,
            lineHeight: 1.5,
          },
          '& .MuiTablePagination-select:focus': {
            backgroundColor: '#fff',
          },
          '& .MuiTablePagination-selectIcon': {
            right: 0,
          },
        },
      },
    },
  },
  palette: {
    mode: 'light',
    error: {
      dark: 'rgba(255, 46, 59, 0.2)',
      main: '#ff2e3b',
    },
    primary: {
      main: '#8390a0',
    },
    success: {
      main: '#4558f2',
      dark: '#00cd69',
      contrastText: 'rgba(0, 205, 105, 0.2)',
    },
    secondary: {
      main: '#00263e',
    },
    transparent: {
      main: 'rgba(155, 171, 197, 0.1)',
      secondary: 'rgba(203, 203, 203, 0.7)',
    },
    warning: {
      main: '#ffa92d',
      dark: 'rgba(255, 169, 45,0.2)',
    },
    info: {
      main: '#23d98e',
      dark: '#f1f1f1',
    },
    action: {
      hover: '#4558f2',
      selected: '#4558f2',
    },
    border: {
      main: '#8390a0',
      dark: '#c4c5c6',
    },
    background: {
      paper: '#f6f7f9',
      default: '#fff',
      container: '#f1f1f1',
    },
    foreground: {
      default: '#2b2a2a',
    },
    logo: {
      main: '#4558f2',
    },
    divider: '#000',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1450,
    },
  },
});
