import React, { useState, useCallback } from 'react';
import { Box } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import useNotification from 'client/hooks/useNotification';
import PaymentCardInput from './components/PaymentCardInput/PaymentCardInput';
import { PaymentCardButton } from './PaymentCard.styled';
import axios from 'axios';
import Cookies from 'js-cookie';
type PaymentCardProps = {
  balanceAdd?: boolean;
  secret: string | undefined;
  onSuccess: () => void;
  amount: number;
};

export const PaymentCard: React.FC<PaymentCardProps> = ({
  balanceAdd,
  secret,
  onSuccess,
  amount
}) => {
  const { setError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();


  const updateBalance = async (amount: number) => {
    try {
      const accessToken = Cookies.get('access_token');
      const apiUrl = process.env.API_BASE_URL || API_BASE_URL
      console.log("apiUrl", apiUrl)
      if (!accessToken) {
        throw new Error('Access token not found');
      }

      await axios.patch(
        `${apiUrl}/balance`,
        { amount },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log('Balance updated successfully!');
    } catch (apiError) {
      console.error('API call failed:', apiError);
      throw new Error('Failed to update balance');
    }
  };

  // Hàm proceedPayment gắn vào trực tiếp
  const proceedPayment = useCallback(
    async (secret: string | undefined, tokenId?: string) => {
      if (!stripe || !secret) {
        setLoading(false);
        return Promise.reject('Payment Error');
      }

      setLoading(true);

      try {
        let paymentMethod;

        if (tokenId) {
          paymentMethod = {
            card: { token: tokenId },
          };
          console.log("Using tokenId, skipping additional confirmation.");
          setLoading(false);
          return;
        } else {
          if (!elements) {
            setLoading(false);
            return Promise.reject('Payment Error');
          }
          const cardElement = elements.getElement(CardElement);
          if (!cardElement) {
            setLoading(false);
            return Promise.reject('Payment Error');
          }

          const { paymentMethod: newPaymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
          });

          if (error) {
            setLoading(false);
            return Promise.reject(error.message);
          }
          paymentMethod = newPaymentMethod?.id;
        }

      
        const { error } = await stripe.confirmCardPayment(secret, {
          payment_method: paymentMethod,
        });

        if (error) {
          setLoading(false);
          return Promise.reject(error.message); 
        }
        await updateBalance(amount)
        console.log("Payment successful!");
      } catch (error) {
        setLoading(false);
        return Promise.reject(error);
      } finally {
        setLoading(false);
      }
    },
    [stripe, elements],
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    proceedPayment(secret)
      .then(onSuccess)
      .catch((error) => setError(error));
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <PaymentCardInput />
      <PaymentCardButton balanceAdd={balanceAdd} loading={loading} />
    </Box>
  );
};

export default PaymentCard;
