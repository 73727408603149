import React, { forwardRef } from 'react';

import { LoadingButton } from '@mui/lab';

import { PaymentCardControlWrapper } from './PaymentCardControl.styled';

type PaymentCardControlProps = {
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  balanceAdd?: boolean;
};

export const PaymentCardControl = forwardRef<
  typeof PaymentCardControlWrapper,
  PaymentCardControlProps
>(({ disabled, loading, className, balanceAdd, ...props }, ref) => {
  return (
    <PaymentCardControlWrapper className={className} ref={ref} {...props}>
      <LoadingButton
        disabled={disabled}
        style={{ width: balanceAdd ? '100%' : 'auto' }}
        loading={loading}
        variant="contained"
        type="submit"
      >
        Pay
      </LoadingButton>
    </PaymentCardControlWrapper>
  );
});

PaymentCardControl.displayName = 'PaymentCardControl';

export default PaymentCardControl;
