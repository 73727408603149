import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SavedPaymentMethodContainer = styled(Box)(({ theme }) => ({
  paddingTop: 16,
  paddingBottom: 16,
  backgroundColor: theme.palette.background.container,
  color: theme.palette.secondary.main,
}));

export const SavedPaymentMethodContent = styled(Box)(({ theme }) => ({
  borderColor: theme.palette.transparent.secondary,
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 4,
  padding: 12,
}));

export const SavedPaymentMethodCardInfo = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
}));

export const SavedPaymentMethodCardText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 600,
}));
