import { useMemo, useState, useCallback } from 'react';

import useNotification from 'client/hooks/useNotification';
import * as api from 'client/services/account';

import { AccountSettingsType } from '../services/account/types/account-settings.type';

type UseAccountSettingsType = {
  loading: boolean;
  setAccountSettings: (params: AccountSettingsType) => void;
};

export const useAccountSettings = (): UseAccountSettingsType => {
  const { setError } = useNotification();

  const [loading, setLoading] = useState<boolean>(false);

  const setAccountSettings = useCallback(
    (params: AccountSettingsType) => {
      setLoading(true);
      api
        .setAccountSettings(params)
        .catch((error) => setError(error.message))
        .finally(() => setLoading(false));
    },
    [setError],
  );
  return useMemo(
    () => ({
      loading,
      setAccountSettings,
    }),
    [loading, setAccountSettings],
  );
};

export default useAccountSettings;
