import { useCallback, useMemo } from "react";
import { User } from "client/services/user/types/user.type";
import useAuthContext from "./useAuthContext";
import useNotification from "./useNotification";
import { removeAccessToken } from "client/utils/local-storage";

type LogoutType = {
  logout: () => void;
};

export const useLogout = (): LogoutType => {
  const { setUser } = useAuthContext();
  const { setError } = useNotification();

  const logout = useCallback(async () => {
    try {
      removeAccessToken();
      setUser({} as User);
    } catch (error) {
      setError(error);
    }
  }, [setUser, setError]);

  return useMemo(
    () => ({
      logout,
    }),
    [logout]
  );
};
