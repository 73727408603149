import React from 'react';

import { SimNameEnum } from 'client/enums/sim-name.enum';
import { Order } from 'client/services/order/types/order';

import CurrencyFormat from '../../../CurrencyFormat/CurrencyFormat';
import { InfoDivider } from '../../Invoice.styled';
import {
  InvoiceTotalContainer,
  TotalHeadingContainer,
  HeadingItem,
  QuantityItem,
  EarningItem,
  DescriptionItem,
  HeaderText,
  TotalRowFirst,
  FirstRowText,
  CurrencyText,
  GreenCurrencyText,
  ShippingColumn,
  ShippingInfoColumn,
} from './InvoiceTotal.styled';

type OrdersItemProps = {
  orderData: Order;
};

export const InvoiceTotal: React.FC<OrdersItemProps> = ({ orderData }) => {
  return (
    <InvoiceTotalContainer>
      <TotalHeadingContainer>
        <EarningItem>
          <HeaderText>ID</HeaderText>
        </EarningItem>
        <QuantityItem>
          <HeaderText>QTY</HeaderText>
        </QuantityItem>
        <DescriptionItem>
          <HeaderText>DESCRIPTION</HeaderText>
        </DescriptionItem>
        <HeadingItem>
          <HeaderText>UNIT PRICE</HeaderText>
        </HeadingItem>
      </TotalHeadingContainer>
      <TotalRowFirst>
        <EarningItem>
          <FirstRowText>{orderData.id}</FirstRowText>
        </EarningItem>
        <QuantityItem>
          <FirstRowText>{orderData.amount}</FirstRowText>
        </QuantityItem>
        <DescriptionItem>
          <FirstRowText>
            {SimNameEnum[orderData.type]} - {orderData.amount}SIMs
          </FirstRowText>
        </DescriptionItem>
        <HeadingItem>
          <GreenCurrencyText>
            <CurrencyFormat
              amount={orderData.subtotal / orderData.amount}
              cents
            />
          </GreenCurrencyText>
        </HeadingItem>
      </TotalRowFirst>
      <InfoDivider />
      <TotalRowFirst>
        <ShippingColumn>
          <CurrencyText>Subtotal:</CurrencyText>
          <CurrencyText>Shipping:</CurrencyText>
          <CurrencyText>Tax:</CurrencyText>
        </ShippingColumn>
        <ShippingInfoColumn>
          <CurrencyText>
            <CurrencyFormat amount={orderData.subtotal} cents />
          </CurrencyText>
          <CurrencyText>
            <CurrencyFormat amount={orderData.shipping} cents />
          </CurrencyText>
          <GreenCurrencyText>
            <CurrencyFormat amount={orderData.tax} cents />
          </GreenCurrencyText>
        </ShippingInfoColumn>
      </TotalRowFirst>
      <InfoDivider />
      <TotalRowFirst>
        <ShippingColumn>
          <CurrencyText>Total:</CurrencyText>
        </ShippingColumn>
        <ShippingInfoColumn>
          <GreenCurrencyText>
            <CurrencyFormat amount={orderData.total} cents />
          </GreenCurrencyText>
        </ShippingInfoColumn>
      </TotalRowFirst>
      <InfoDivider />
    </InvoiceTotalContainer>
  );
};
