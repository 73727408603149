import React, { useCallback } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Dialog, PaperProps } from '@mui/material';
import { Breakpoint } from '@mui/system';

import { useConfirmationDialog } from '../../hooks/useConfirmationDialog.hook';
import {
  PopupContainer,
  PopupClose,
  PopupContent,
  PopupIcon,
} from './Popup.styled';

type PopupProps = {
  open: boolean;
  onClose: () => void;
  type?: 'content' | 'fullscreen';
  background?: 'dark' | 'light';
  maxWidth?: Breakpoint | false;
  message?: string;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  checkConfirmationOnClose?: boolean;
};

enum CloseReasons {
  BackdropClick = 'backdropClick',
  EscapeKeyDown = 'escapeKeyDown',
}

export const Popup: React.FC<PopupProps> = ({
  open,
  onClose,
  type = 'content',
  background = 'dark',
  maxWidth = false,
  message = 'Do you really want to close the modal?',
  disableEscapeKeyDown = false,
  disableBackdropClick = false,
  checkConfirmationOnClose = false,
  children,
}) => {
  const { checkConfirmation, ConfirmationDialog } = useConfirmationDialog();

  const closeModal = useCallback(
    (reason?: CloseReasons) => {
      if (disableBackdropClick && reason === 'backdropClick') return false;

      if (disableEscapeKeyDown && reason === 'escapeKeyDown') return false;

      onClose();
    },
    [onClose, disableBackdropClick, disableEscapeKeyDown],
  );

  const handleClose = useCallback(
    (reason?: CloseReasons) => {
      if (checkConfirmationOnClose)
        checkConfirmation(message, () => closeModal(reason));
      else closeModal(reason);
    },
    [checkConfirmationOnClose, checkConfirmation, message, closeModal],
  );

  const handleDialogOnCloseCallback = useCallback(
    (event: unknown, reason: CloseReasons) => handleClose(reason),
    [handleClose],
  );

  const handleClickOnCloseButton = useCallback(
    () => handleClose(),
    [handleClose],
  );
  return (
    <>
      <ConfirmationDialog />
      <Dialog
        open={open}
        scroll="body"
        onClose={handleDialogOnCloseCallback}
        PaperComponent={PopupContainer}
        PaperProps={{ type, background } as PaperProps}
        maxWidth={maxWidth}
      >
        <PopupClose onClick={handleClickOnCloseButton}>
          <PopupIcon component={CloseIcon} stroke={background} />
        </PopupClose>
        <PopupContent>{children}</PopupContent>
      </Dialog>
    </>
  );
};

export default Popup;
