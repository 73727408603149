import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { loadableReady } from '@loadable/component';
import Baseline from '@mui/material/CssBaseline';

import { Notification } from 'client/components';
import { AuthProvider } from 'client/hooks/useAuthContext';
import { NotificationProvider } from 'client/hooks/useNotification';
import Routes from 'client/pages';
import { WithAxios } from 'client/utils/WithAxios';

import { ThemeProvider } from './context/color';

const cache = createCache({ key: 'css', prepend: true });

loadableReady(() => {
  hydrate(
    <BrowserRouter>
      <CacheProvider value={cache}>
        <AuthProvider>
          <ThemeProvider>
            <NotificationProvider>
              <WithAxios>
                <Baseline />
                <Notification />
                <Routes />
              </WithAxios>
            </NotificationProvider>
          </ThemeProvider>
        </AuthProvider>
      </CacheProvider>
    </BrowserRouter>,
    document.getElementById('root'),
  );
});

if (module.hot) module.hot.accept();
