import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';

import { Header, Sidebar } from 'client/components';

const LayoutContainer = styled(Container)(() => ({
  display: 'flex',
}));

const LayoutWrapperBox = styled(Box)(() => ({
  flexGrow: 1,
}));

const LayoutContentBox = styled(Box)(({ theme }) => ({
  paddingLeft: 132,
  paddingRight: 32,
  [theme.breakpoints.down('md')]: {
    paddingLeft: 32,
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  '@media(max-width:365px)': {
    paddingLeft: 3,
    paddingRight: 3,
  },
  '@media print': {
    position: 'fixed',
  },
}));

export type DrawerProps = {
  isOpen: boolean;
  toggleDrawer: (drawer: boolean) => void;
};

export const Layout: React.FC = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const toggleDrawer = (drawer: boolean) => {
    setOpen(drawer);
  };

  return (
    <LayoutContainer maxWidth={false} disableGutters>
      <Sidebar toggleDrawer={toggleDrawer} isOpen={isOpen} />
      <LayoutWrapperBox>
        <Header isOpen={isOpen} toggleDrawer={toggleDrawer} />
        <LayoutContentBox component="main">{children}</LayoutContentBox>
      </LayoutWrapperBox>
    </LayoutContainer>
  );
};
