import React, { useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl,
  FormControlProps,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

interface PasswordInputPropsType extends FormControlProps {
  label: string;
  validationError?: boolean;
  inputRef: React.Ref<unknown>;
}

export const PasswordInput = (
  props: PasswordInputPropsType,
): React.ReactElement => {
  const { label, validationError, inputRef, ...otherProps } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl {...otherProps}>
      <InputLabel error={validationError} htmlFor="outlined-adornment-password">
        {label}
      </InputLabel>
      <OutlinedInput
        id={label}
        inputRef={inputRef}
        error={validationError}
        name={label.toLowerCase()}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  );
};

export default PasswordInput;
