import React from 'react';

import { OutlinedInput } from '@mui/material';

import { InputCaption, InputControl } from './Input.styled';

type InputProps = {
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  value?: unknown;
  inputProps?: unknown;
  defaultValue?: unknown;
  className?: string;
  onChange?: (value: string) => void;
};

export const Input: React.FC<InputProps> = ({
  label,
  name,
  required,
  disabled,
  value,
  defaultValue,
  inputProps,
  className,
  onChange,
}) => {
  return (
    <InputControl
      variant="outlined"
      size="small"
      required={required}
      className={className}
    >
      {label && <InputCaption shrink={true}>{label}</InputCaption>}
      <OutlinedInput
        name={name}
        value={value}
        defaultValue={defaultValue}
        required={required}
        disabled={disabled}
        onChange={
          onChange ? (event) => onChange(event.target.value) : undefined
        }
        {...inputProps}
      />
    </InputControl>
  );
};

export default Input;
