import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InvoiceMainInfoContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 25,
  flexFlow: 'row wrap',
  '@media print': {
    flexFlow: 'row nowrap',
  },
}));

export const IssuerInfoContainer = styled(Box)(() => ({
  maxWidth: 411,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
}));

export const BilledInfoContainer = styled(IssuerInfoContainer)(() => ({
  maxWidth: 350,
}));

export const IssuerHeading = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 5,
}));

export const IssuerHeader = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.common.black,
  letterSpacing: 0.8,
  fontWeight: 'bold',
}));

export const InvoiceIssuerInfo = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: 10,
}));

export const InnerIssuerHeader = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  color: theme.palette.common.black,
  letterSpacing: 1,
  fontWeight: 600,
}));

export const Info = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.common.black,
  letterSpacing: 0.6,
  fontWeight: 'normal',
  lineHeight: 1.5,
  whiteSpace: 'nowrap',
  '@media print': {
    whiteSpace: 'normal',
  },
}));

export const InfoCustomerId = styled(Typography)(({ theme }) => ({
  fontSize: 9,
  color: theme.palette.common.black,
  letterSpacing: 0.6,
  fontWeight: 'normal',
  lineHeight: 1.5,
  whiteSpace: 'nowrap',
  '@media print': {
    whiteSpace: 'normal',
  },
}));
