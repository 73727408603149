import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { ReactComponent as SignalIcon } from './Signal.svg';

export const Signal: React.FC = ({
  sx = { height: 18, width: 18, marginRight: '5px' },
  viewBox = '0 0 18 18',
  ...iconProps
}: SvgIconProps) => (
  <SvgIcon component={SignalIcon} sx={sx} viewBox={viewBox} {...iconProps} />
);
