import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InvoiceTotalContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const TotalHeadingContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: 25,
  paddingRight: 25,
  paddingTop: 10,
  paddingBottom: 10,
  width: '100%',
}));

export const HeadingItem = styled(Box)(() => ({
  display: 'flex',
  width: '20%',
  justifyContent: 'flex-end',
}));

export const QuantityItem = styled(HeadingItem)(() => ({
  width: '10%',
  justifyContent: 'flex-start',
}));

export const EarningItem = styled(HeadingItem)(() => ({
  width: '40%',
  justifyContent: 'flex-start',
}));

export const DescriptionItem = styled(HeadingItem)(() => ({
  width: '30%',
  justifyContent: 'flex-start',
}));

export const ShippingColumn = styled(HeadingItem)(() => ({
  width: '80%',
  flexDirection: 'column',
  alignItems: 'flex-end',
}));

export const ShippingInfoColumn = styled(HeadingItem)(() => ({
  flexDirection: 'column',
  alignItems: 'flex-end',
}));

export const HeaderText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  color: theme.palette.common.white,
}));

export const TotalRowFirst = styled(TotalHeadingContainer)(({ theme }) => ({
  paddingBottom: 0,
  paddingTop: 0,
  backgroundColor: theme.palette.common.white,
  alignItems: 'center',
  marginTop: 15,
  marginBottom: 15,
}));

export const FirstRowText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  letterSpacing: 0.6,
  lineHeight: 1.5,
  color: theme.palette.common.black,
  whiteSpace: 'nowrap',
}));

export const CurrencyText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  letterSpacing: 0.8,
  color: theme.palette.common.black,
  lineHeight: 1.5,
}));
export const GreenCurrencyText = styled(CurrencyText)(({ theme }) => ({
  color: theme.palette.success.dark,
}));
