import React from 'react';

import {
  Box,
  BoxProps,
  Checkbox,
  InputLabel,
  SvgIcon,
  SvgIconProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const ThemeTogglerWrapper = styled(InputLabel)(() => ({
  width: 65,
  display: 'block',
  margin: 'auto',
}));

type ThemeTogglerItemProps = BoxProps & {
  checked: boolean;
};

export const ThemeTogglerItem = styled(Box)<ThemeTogglerItemProps>(
  ({ checked, theme }) => ({
    height: 32.5,
    width: '100%',
    background: theme.palette.foreground.default,
    borderRadius: 40,
    padding: 6,
    position: 'relative',
    transition: 'background 0.5s ease',
    cursor: 'pointer',
    '&::before': {
      content: '""',
      display: 'block',
      height: 20.5,
      width: 20.5,
      borderRadius: 30,
      background: theme.palette.background.default,
      position: 'absolute',
      zIndex: 2,
      transform: 'translate(0)',
      transition: 'transform 0.5s ease, background 0.5s ease',
      ...(checked && {
        transform: 'translate(32.5px)',
      }),
    },
  }),
);

export const ThemeTogglerIcons = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  margin: '0 2.5px',
}));

export const ThemeTogglerInput = styled(Checkbox)(() => ({
  opacity: 0,
  position: 'absolute',
  top: 0,
}));

export const ThemeTogglerIcon = styled(SvgIcon)<
  SvgIconProps & {
    component: React.ElementType;
  }
>(({ theme }) => ({
  fill: theme.palette.background.default,
  height: 15,
  width: 15,
  zIndex: 0,
}));
