import React from 'react';

import { RoutesEnum } from 'client/enums/routes.enum';

import useAuthContext from '../../hooks/useAuthContext';
import { StatusUserEnum } from '../../services/user/types/user.type';
import { SidebarItemButton } from './SidebarListItemButton.styled';

type Props = {
  component: React.ElementType;
  to: string;
  exact?: boolean;
  children: JSX.Element[] | string;
  onClick: () => void;
};

const SidebarListItemButton: React.FC<Props> = (props) => {
  const { user } = useAuthContext();
  const disableNavigationCheck = () => {
    return (
      user.status === StatusUserEnum.Inactive ||
      (user.client.status === StatusUserEnum.Blocked &&
        props.to !== RoutesEnum.Billing)
    );
  };
  return (
    <SidebarItemButton disabled={disableNavigationCheck()} {...props}>
      {props.children}
    </SidebarItemButton>
  );
};

export default SidebarListItemButton;
