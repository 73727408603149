import { Client } from '../../client/types/client';
import { Role } from '../../roles/types/role.type';

export enum StatusUserEnum {
  New = 'new',
  Active = 'active',
  Inactive = 'inactive',
  Deleted = 'deleted',
  Blocked = 'blocked',
}

export type User = {
  accountSettings?: {
    theme: string;
  };
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  cognitoId: string;
  mfaEnabled: boolean;
  phoneVerified: boolean;
  phone: string;
  status: StatusUserEnum;
  address: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
  photo?: string;
  roleId: string;
  role: Role;
  clientId: string;
  client: Client;
  name?: string;
};
