import React from 'react';

import moment from 'moment/moment';

type DateFormatPropsType = {
  date: string;
  dateFormat?: DateFormatEnum;
};

export enum DateFormatEnum {
  Date = 'DD/MM/YYYY',
  ShortDate = 'LL',
  FullTime = 'HH:mm:ss',
  DateTime = 'DD/MM/YYYY HH:mm',
  DateFullMonth = 'DD MMMM',
  FullDateTime = 'DD/MM/YYYY HH:mm:ss',
}

export const DateFormat = ({
  date,
  dateFormat = DateFormatEnum.FullDateTime,
}: DateFormatPropsType): React.ReactElement => (
  <>{moment(date).format(dateFormat)}</>
);

export default DateFormat;
